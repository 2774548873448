import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import { jwtUserInfo } from "../helpers/authenticationHelper";
import { refreshToken, logoutUser } from "../models/auth";
import { Store } from "../models/store/Store.interface";
import router from "../router";

const state: Store = {
  //version: import.meta.env.VERSION,
  // eslint-disable-next-line no-undef
  version: __APP_VERSION__,
  //version: import.meta.env.__APP_VERSION__ + "test",
  userSettings: {
    darkMode:
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches,
    sideBar: true,
    language: "en",
  },
  accessid: undefined,
  token: undefined,
  toast: undefined,
  iconPrefix: "fa",
  sortUp: "fa-sort-amount-desc",
  sortDown: "fa-sort-amount-asc",
  defaultSort: "fa-sort",
  lineChartCompKey: 0, //line graph component key
  DonutChartCompKey: 0, //donut graph component key
};

export const store = createStore({
  state: state,
  mutations: {
    setDarkMode(state: Store) {
      state.userSettings.darkMode = !state.userSettings.darkMode;
    },
    setSideBar(state: Store) {
      state.userSettings.sideBar = !state.userSettings.sideBar;
    },
    logout(state: Store) {
      state.token = undefined;
      state.version = undefined;
    },
    login(state: Store, token) {
      // eslint-disable-next-line no-undef
      state.version = __APP_VERSION__;
      state.token = token;
      // state.version = import.meta.env.VERSION;
    },
    settoast(state: Store, toastObject) {
      state.toast = toastObject;
    },
    cleartoast(state: Store) {
      state.toast = undefined;
    },
    updatetoken(state: Store, token: string) {
      state.token = token;
    },
    setTableOptions(state: Store, accessid) {
      state.accessid = accessid;

      const tableOptionsEl = document.getElementById(accessid);
      const allTableOptions = document.querySelectorAll(".table-options");
      if (accessid === -1) {
        allTableOptions.forEach((el) => {
          el.style.display = "none";
        });
        return;
      }
      const displayValue = getComputedStyle(tableOptionsEl, null).display;
      // disable all menu taost divs
      allTableOptions.forEach((el) => {
        el.style.display = "none";
      });
      if (displayValue == "none") {
        tableOptionsEl.style.display = "flex";
      } else {
        tableOptionsEl.style.display = "none";
      }
    },
    setConfirmDialog(state: Store, dialogId) {
      let dialogBox = document.getElementById(`confirm-box-${dialogId}`);
      let tableOptions = document.getElementById(dialogId);
      let allConfirmationBox = document.querySelectorAll(".confirmation-box");
      let displayValue = getComputedStyle(dialogBox, null).display;

      if (tableOptions?.style.display) {
        tableOptions.style.display = "none";
      }

      allConfirmationBox.forEach((el) => {
        el.style.display = "none";
      });
      if (displayValue == "none") {
        dialogBox.style.display = "flex";
      } else {
        dialogBox.style.display = "none";
      }
    },
    setTableRowExpand(state: Store, evt) {
      try {
        // let expanderTrId = event.originalEvent.explicitOriginalTarget.getAttribute('aria-controls');
        let expanderBtn = evt.originalEvent.explicitOriginalTarget;
        if (!expanderBtn) {
          expanderBtn = evt.originalEvent.srcElement;
        }

        let tableRow = null;
        if (expanderBtn.className == "p-row-toggler p-link") {
          tableRow = expanderBtn.parentNode.parentNode;
        } else {
          try {
            tableRow = expanderBtn.parentNode.parentNode.parentNode;
          } catch (e) {
            tableRow = expanderBtn.parentNode.parentNode.parentNode.parentNode;
          }
        }
        const tableRowCls = tableRow.className;

        setTimeout(() => {
          const expandedRow = document.querySelectorAll(
            ".p-datatable-row-expansion",
          );
          const lastExpandedRow = expandedRow[expandedRow.length - 1];

          try {
            if (tableRowCls.includes("table-inprogress")) {
              lastExpandedRow.classList.add(
                `${tableRowCls}-expansion-inprogress`,
              );
            } else if (tableRowCls.includes("table-success")) {
              lastExpandedRow.classList.add(`${tableRowCls}-expansion-success`);
            } else if (tableRowCls.includes("nocustomers")) {
              lastExpandedRow.classList.add(
                `${tableRowCls}-expansion-nocustomers`,
              );
            } else {
              lastExpandedRow.classList.add(`${tableRowCls}-expansion`);
            }
          } catch (e) {
            let splitClsNames = tableRowCls.split(" ");
            let lastClsName = splitClsNames[splitClsNames.length - 1];
            if (splitClsNames.includes("table-inprogress")) {
              lastExpandedRow.classList.add(
                `${lastClsName}-expansion-inprogress`,
              );
            } else if (splitClsNames.includes("table-success")) {
              lastExpandedRow.classList.add(`${lastClsName}-expansion-success`);
            } else if (splitClsNames.includes("nocustomers")) {
              lastExpandedRow.classList.add(
                `${lastClsName}-expansion-nocustomers`,
              );
            } else {
              lastExpandedRow.classList.add(`${lastClsName}-expansion`);
            }
          }
        }, 1000);
      } catch (e) {
        /* empty */
      }
    },
    setRefreshComponent(state: Store) {
      state.lineChartCompKey += 1; // Change the key to re-render the line graph component
      state.DonutChartCompKey += 1; // Change the key to re-render the donut graph component
    },
  },
  actions: {
    refreshGraphComponent({ commit }) {
      commit("setRefreshComponent");
    },
    tableRowExpand({ commit }, evt) {
      commit("setTableRowExpand", evt);
    },
    toggleDarkMode({ commit }) {
      commit("setDarkMode");
    },
    toggleSideBar({ commit }) {
      commit("setSideBar");
    },
    toggleTableOptions({ commit }, accessid) {
      commit("setTableOptions", accessid);
    },
    toggleConfirmationDialog({ commit }, dialogId) {
      commit("setConfirmDialog", dialogId);
    },
    logout({ commit }, reasonobj) {
      logoutUser();
      commit("logout");
      if (reasonobj) {
        if (reasonobj.reason === "tokenexpired") {
          if (!router.currentRoute.value.fullPath.includes("redirect=/")) {
            this.dispatch("settoast", {
              type: "error",
              title: "Token expired",
              message: "Session token has expired. You have been logged out",
              time: 10000,
            });

            router.push({
              path: "/login",
              query: { redirect: router.currentRoute.value.fullPath },
            });
          }
        }
      } else {
        this.dispatch("settoast", {
          type: "info",
          title: "Logged out",
          message: "Logged out succesfully.",
          time: 5000,
        });
        router.push({ name: "Login" });
      }
    },
    refreshToken({ commit }, token) {
      refreshToken(token)
        .then((response) => {
          commit("login", response.headers["authentication-token"]);
          const oldUserInfo = jwtUserInfo(token);
          const userInfo = jwtUserInfo(
            response.headers["authentication-token"],
          );
          if (oldUserInfo.grp !== userInfo.grp) {
            // Force user to a different page to ensure old data isn't visible anymore
            router.push("/");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    cleartoast({ commit }) {
      commit("cleartoast");
    },
    settoast({ commit }, toastObject) {
      commit("settoast", toastObject);
    },
    updateToken({ commit }, token) {
      commit("updatetoken", token);
    },
  },
  modules: {},
  getters: {
    darkMode(state) {
      return state.userSettings.darkMode;
    },
    sideBar(state) {
      return !state.userSettings.sideBar;
    },
    userProfile(state) {
      return jwtUserInfo(state.token);
    },
    token(state) {
      return state.token;
    },
    version(state) {
      // eslint-disable-next-line no-undef
      return state.version || __APP_VERSION__;
    },
    getToast(state) {
      return state.toast;
    },
  },
  plugins: [createPersistedState()],
});
