import * as Sentry from "@sentry/vue";
import HighchartsVue from "highcharts-vue";
import mitt from "mitt";
import Avatar from "primevue/avatar";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import Card from "primevue/card";
import Checkbox from "primevue/checkbox";
import Chip from "primevue/chip";
import Column from "primevue/column";
import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmPopup from "primevue/confirmpopup";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import Fieldset from "primevue/fieldset";
import Inplace from "primevue/inplace";
import InputNumber from "primevue/inputnumber";
import InputSwitch from "primevue/inputswitch";
import InputText from "primevue/inputtext";
import Message from "primevue/message";
import MultiSelect from "primevue/multiselect";
import Password from "primevue/password";
import ProgressBar from "primevue/progressbar";
import ProgressSpinner from "primevue/progressspinner";
import RadioButton from "primevue/radiobutton";
import ScrollPanel from "primevue/scrollpanel";
import SelectButton from "primevue/selectbutton";
import Skeleton from "primevue/skeleton";
import TabPanel from "primevue/tabpanel";
import TabView from "primevue/tabview";
import Tag from "primevue/tag";
import Textarea from "primevue/textarea";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import Tooltip from "primevue/tooltip";
import { createApp } from "vue";
import VueJsonPretty from "vue-json-pretty";
import VueMarkdown from "vue-markdown-render";

import App from "./App.vue";
import router from "./router";
import { store } from "./store";

import "vue-json-pretty/lib/styles.css";
import "primevue/resources/primevue.min.css";
// import "primevue/resources/themes/saga-blue/theme.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "font-awesome/css/font-awesome.min.css";

// Emitter
const emitter = mitt();

const app = createApp(App);

Sentry.init({
  app,
  // eslint-disable-next-line no-undef
  dsn: __SENTRY_DSN__,
  // eslint-disable-next-line no-undef
  environment: __SENTRY_ENVIRONMENT__,
  // eslint-disable-next-line no-undef
  dist: __SENTRY_DIST__,
  // eslint-disable-next-line no-undef
  enabled: __SENTRY_ENVIRONMENT__ != "local_development",
  // there is no server name in the js(vue) sentry sdk

  integrations: [
    Sentry.browserTracingIntegration({ router }), // tracing
    Sentry.replayIntegration(), // session replay
    Sentry.browserProfilingIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  // Learn more at
  // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [/^\/api\//],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  // Learn more at
  // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // result in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
});

app
  .use(store)
  .use(router)
  .use(PrimeVue, { ripple: true })
  .use(ToastService)
  .use(ConfirmationService)
  .use(HighchartsVue)
  .component("Card", Card)
  .component("Toast", Toast)
  .component("Button", Button)
  .component("InputText", InputText)
  .component("Password", Password)
  .component("InputNumber", InputNumber)
  .component("Textarea", Textarea)
  .component("Checkbox", Checkbox)
  .component("Dropdown", Dropdown)
  .component("DataTable", DataTable)
  .component("ScrollPanel", ScrollPanel)
  .component("Column", Column)
  .component("Calendar", Calendar)
  .component("MultiSelect", MultiSelect)
  .component("SelectButton", SelectButton)
  .component("RadioButton", RadioButton)
  .component("Dialog", Dialog)
  .component("Message", Message)
  .component("ConfirmPopup", ConfirmPopup)
  .component("Fieldset", Fieldset)
  .component("Avatar", Avatar)
  .component("ProgressSpinner", ProgressSpinner)
  .component("ProgressBar", ProgressBar)
  .component("InputSwitch", InputSwitch)
  .component("Skeleton", Skeleton)
  .component("Inplace", Inplace)
  .component("Chip", Chip)
  .component("Tag", Tag)
  .component("TabView", TabView)
  .component("TabPanel", TabPanel)
  .component("VueJson", VueJsonPretty)
  .component("VueMarkdown", VueMarkdown)
  .provide("emitter", emitter)
  .directive("tooltip", Tooltip)
  .mount("#app");
