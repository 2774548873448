import API from "../../models/api-client";

import {
  AuthParameterInterface,
  TwoFactorAuthInterface,
} from "./Auth.interface";

export const getToken = (dataObject: AuthParameterInterface) => {
  return API.post({
    url: "/login",
    payload: dataObject,
  });
};

export const getTwofaToken = (dataObject: TwoFactorAuthInterface) => {
  return API.post({
    url: "/twofactor",
    payload: dataObject,
  });
};

export const refreshToken = (token: string) => {
  return API.post({
    url: "/refreshToken",
    payload: {
      token: token,
    },
  });
};

export const postForgotPassword = (username: string) => {
  return API.post({
    url: "/forgotPassword",
    payload: {
      username: username,
    },
  });
};

export const savePassword = (rtoken: string, password: string) => {
  return API.post({
    url: "/savePassword",
    payload: {
      rtoken: rtoken,
      password: password,
    },
  });
};

export const validateResetToken = (rtoken: string) => {
  return API.post({
    url: "/validateResetToken",
    payload: {
      rtoken: rtoken,
    },
  });
};

export const logoutUser = () => {
  return API.post({
    url: "/logout",
  });
};
